import React from "react";

export function IPSmallLogo(): React.ReactElement {
  return (
    <svg
      data-name="Italian Passport Logo"
      height="29.55"
      viewBox="0 0 38.096 29.55"
      width="38.096"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.242 29.586l-14.564 4.5L0 9.207l14.563-4.5a4 4 0 015 2.643l5.319 17.231a4 4 0 01-2.642 5"
        data-name="Path 6273"
        fill="#9aa7a0"
        transform="translate(0 -4.532)"
      />
      <path
        d="M13.032 29.408L27.595 33.9a4 4 0 005-2.642l5.321-17.228a4 4 0 00-2.643-5L20.71 4.533z"
        data-name="Path 6274"
        fill="#de5757"
        transform="translate(0 -4.532)"
      />
      <path
        d="M19.7 7.811l-6.67 21.61 4.9 1.511 4.312-1.332a4 4 0 002.642-5z"
        data-name="Path 6275"
        fill="#083631"
        transform="translate(0 -4.532)"
      />
      <path
        d="M0 0H38.096V29.549H0z"
        data-name="Rectangle 1343"
        fill="none"
        transform="translate(0 .001)"
      />
    </svg>
  );
}
